import { styled } from "styled-components";

const Container = styled.div`
    display: flex;
`;

const ImgContainer = styled.div`
    width: 100%;
    position: relative;
    min-height: 50vh;
`;

const BlurLayer = styled.div`
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BrandText = styled.span`
    font-size: 75px;
`;

const OverlayText = styled.h3`
    text-align: center;
    font-size: 83px;
    color:#FFFFFF;
    max-width: ${({ textWidth }) => textWidth || '70%'};

    ${BrandText}{
        color:#8AE635;
    }
`;

const HeroImageSection = ({ title, highlightTitle, imgSrc, textWidth }) => {
    return <Container>
        <ImgContainer>
            <img src={imgSrc} style={{ width: '100%', minHeight: '100%', objectFit: 'cover' }} />
            <BlurLayer>
                <OverlayText textWidth={textWidth} >{title} <BrandText>{highlightTitle}</BrandText></OverlayText>
            </BlurLayer>
        </ImgContainer>
    </Container>
}

export default HeroImageSection;