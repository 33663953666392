import './App.css';

import {
  RouterProvider,createBrowserRouter
} from "react-router-dom";

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Projects from './pages/Projects';
import DetailProject from './pages/DetailProject';
import AgriculturalMethodology from './pages/AgriculturalMethodology';
import ContactUs from './pages/ContactUs';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/detail-project/:projectSlugId",
    element: <DetailProject />,
  },
  {
    path: "/agricultural-methodology",
    element: <AgriculturalMethodology />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;