import { styled } from "styled-components";
import colors from "../utils/colors";
import { NavMenu, config } from "../utils/staticData";
import { useEffect, useState } from "react";

const MenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const ContactButton = styled.button`
    padding: 0px 3px;
    font-size: 22px;
    font-weight: 400;
    border-radius: 3px;
    /* box-shadow: 0px 4px 4px 0px #00000040; */
    background: #FFFFFF;
    cursor: pointer;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 150px;
    background-color: ${({ colors }) => colors.primary};

    ${ContactButton} {
        color: ${({ colors }) => colors.secondary};

        &:hover{
            color: ${({ colors }) => colors.primary};
        }
    }
`;

const LogoImg = styled.img.attrs({
    src: require('../assets/img/logo.png')
})`
    width: 100px;
    height: 100px;
    object-fit: contain; 
`;

const MenuItem = styled.a`
    color: ${({ colors, activeTabBool }) => activeTabBool ? colors.secondary : colors.background};
    font-size: 22px;
    font-weight: 400;
    margin-right: 20px;
    cursor: pointer;
    text-decoration: none;
    &:hover{
        color: ${({ colors }) => colors.secondary};
    }
`;


const Header = () => {

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        NavMenu.map((item, index) => {
            if (window.location.pathname.includes('detail-project')) {
                setActiveTab(-1);
            } else if (window.location.pathname === '/') {
                setActiveTab(0);
            } else if (item.navPath.includes(window.location.pathname)) {
                setActiveTab(index);
            }
        })
    }, [])


    const renderMenuItem = (item, index, activeTabBool) => {
        return <MenuItem href={item.navPath} key={item.id} activeTabBool={activeTabBool} colors={colors} >{item.title}</MenuItem>
    }

    return <Container colors={colors} >
        <LogoImg />
        <MenuContainer>
            {NavMenu.map((item, index) => { return renderMenuItem(item, index, activeTab === index) })}
            <ContactButton onClick={() => {
                window.open("tel:" + config.phoneNumber);
            }} >{config.phoneNumber}</ContactButton>
        </MenuContainer>
    </Container>
}

export default Header;