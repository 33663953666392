import { styled } from "styled-components";
import colors from "../../utils/colors";

import ActionButton from "../../components/ActionButton";

import leafBg from '../../assets/img/leafLightBg.svg';

import Img1 from '../../assets/img/ourCustomers.svg';

const Heading = styled.h4`
    font-size: 34px;
    font-weight: 700;
    line-height: 40px;
    color: #fff;
    margin-bottom: 15px;
    `;

const Body = styled.h5`
    color: #fff;
    font-size: 21px;
    font-weight: 300;
    line-height: 31px;
    letter-spacing: 0px;
`;

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: #57A227;
    padding: 80px 150px;
    background-image: url(${leafBg});
`;

const RowSection = styled.div`
    display: flex;
    position: relative;
    align-items: center;
`;

const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1.2;
    margin-left: 3%;
`;

const LeftSection = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    margin-right: 3%;
    justify-content: center;
`;

const BgLayer = styled.div`
    width: 400px;
    height: 100%;
    border-radius: 150px 0px 150px 0px;
    box-shadow: 0px 5px 28px 4px #9BB70D;
    background: #7FB911;
    position: absolute;
    margin-left: 40px;
    margin-bottom: 40px;
`;

const ImgLayer = styled.img`
    width: 400px;
    /* border-radius: 50px 0px 150px 0px; */
    height: auto;
    z-index: 9;
`;

const Body2 = styled.p`
    font-size: 21px;
    font-weight: 300;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: center;
    color: #fff;
    width: 75%;
    align-self: center;
    margin-top: 80px;
`;

const OurCustomers = () => {
    return <Container colors={colors} >
        <RowSection>
            <LeftSection>
                <BgLayer />
                <ImgLayer src={Img1} />
            </LeftSection>
            <RightSection>
                <Heading>Our Customers:</Heading>
                <Body>With over 3,000 satisfied customers, we have become a trusted choice for those looking to invest in farmland or plots. Their happiness is our driving force, and we are committed to ensuring that each customer's journey with us is marked by transparency, reliability, and exceptional service.</Body>
            </RightSection>
        </RowSection>
        <Body2>As we continue to expand our horizons, we invite you to join us on this exciting journey. Whether you're a seasoned investor or a first-time buyer, Bhuloka is here to make your real estate dreams a reality. Explore our offerings, and let us help you find the green haven you've been searching for.</Body2>
        <span style={{ borderBottom: '2px dashed #fff', margin: '30px 28% 5px 28%' }} />
        <ActionButton title='Invest Now' alignSelf='center' href="/contact-us" />
    </Container>
}

export default OurCustomers;