import { styled } from "styled-components";

import leafBg from '../../assets/img/leafLightBg.svg';

import img1 from '../../assets/img/img1_bf.svg';
import img2 from '../../assets/img/img2_bf.svg';
import img3 from '../../assets/img/img3_bf.svg';

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: #57A227;
    padding: 80px 150px;
    background-image: url(${leafBg});
`;

const ImgSection = styled.div`
    display: flex;
    margin-bottom: 50px;
    justify-content: center;
`;

const ImgBlock = styled.img`
    border: 1px solid #FFFFFF;
    width: 30%;
    border-radius: 35px;
    margin-right: 20px;
`;

const Text = styled.p`
    font-size: 21px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: justified;
    color:#fff;
`;

const BeforeFooter = () => {
    return <Container>
        <ImgSection>
            <ImgBlock src={img1} />
            <ImgBlock src={img2} />
            <ImgBlock src={img3} />
        </ImgSection>
        <Text>Water scarcity is a significant concern in many parts of India. Modern farming methods promote sustainable irrigation techniques such as drip irrigation and rainwater harvesting. Drip irrigation delivers water directly to the plant's roots, reducing water wastage and increasing crop yields. Rainwater harvesting helps farmers collect and store rainwater during the monsoon season, providing a reliable water source during dry spells. These practices not only conserve water but also make farming more resilient to climate change.</Text>
    </Container>
}

export default BeforeFooter;