import Footer from "../../components/Footer"
import Header from "../../components/Header"

import HeroImageSection from "../../components/HeroImageSection";

import BgImg from '../../assets/img/agricultureMethodologyHeroBanner.svg';
import LandscapeSection from "./LandscapeSection";
import HeroTextual from "./HeroTextual";
import BeforeFooter from "./BeforeFooter";
import DetailBlock from "./DetailBlock";

const AgriculturalMethodology = () => {
    return <>
        <Header />
        <HeroImageSection title={'“Agriculture is the process of turning eco-systems into people.”'} imgSrc={BgImg} />
        <HeroTextual />
        <LandscapeSection />
        <DetailBlock/>
        <BeforeFooter/>
        <Footer />
    </>
}

export default AgriculturalMethodology;