import { styled } from "styled-components";

import leafBg from '../../assets/img/leafLightBg.svg';
import GrassStrip from '../../assets/img/grass.svg';

import singleBottomLeaf from '../../assets/img/topLeftLeaf.svg';
import oneAndHalfLeaf from '../../assets/img/OneandHalfLeaf.svg';
import ThreeSeperateLeaf from '../../assets/img/threeSeperateLeafs.svg';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Fragment } from "react";


const Main = styled.div`
    display: flex;
    flex-direction: column;
    background-image: url(${leafBg});
    position: relative;
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    overflow: hidden;
    `;

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 80px 150px;
`;

const GrassBg = styled.img`
    
`;

const RowBlock = styled.div`
    display: flex;
    margin:30px 0px;
`;

const LeftBlock = styled.div`
    display: flex;
    flex:1;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
`;

const RightBlock = styled.div`
    display: flex;
    flex:1;
    margin-left: 20px;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
`;

const BgLayer = styled.div`
    width: 75%;
    height: 100%;
    border-radius: 37px;
    border: 2px dashed #498A26;
    position: absolute;
    margin-right: 20px;
    margin-bottom: 20px;
`;

const ImgLayer = styled.img`
    width: 75%;
    height: auto;
    z-index: 9;
`;

const Title = styled.h4`
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.392965316772461px;
    text-align: left;
    color:#498A26;
    margin-bottom: 10px;
    align-self: flex-start;
`;

const Desc = styled.p`
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: justified;

`;

const DetailSection = ({ project }) => {
    return <Main>
        <Container style={{ marginTop: '120px' }} >
            <RowBlock>
                <LeftBlock>
                    <img src={ThreeSeperateLeaf} style={{ position: 'absolute', left: '-120px', bottom: '40%', opacity: 0.6 }} />
                    <Title>{project?.detailsData?.firstBlock?.title}</Title>
                    <Desc>{project?.detailsData?.firstBlock?.desc?.title}</Desc>
                    <ul style={{ margin: '10px 0px', padding: '0px', alignSelf: 'flex-start' }} >
                        {project?.detailsData?.firstBlock && project?.detailsData?.firstBlock?.desc?.data.map(o =>
                            <li key={o}>
                                <Desc>{o}</Desc>
                            </li>
                        )}
                    </ul>
                    <img src={singleBottomLeaf} style={{ position: 'absolute', right: '-120px', bottom: '-166px', opacity: 0.6 }} />
                </LeftBlock>
                <RightBlock>
                    <img src={oneAndHalfLeaf} style={{ position: 'absolute', right: '-225px', top: '-166px', opacity: 0.6 }} />
                    <div style={{ display: 'flex', flex: 1, width: '68%' }} >
                        <Carousel autoPlay infiniteLoop showStatus={false} >
                            {
                                project?.detailsData?.firstBlock && project?.detailsData?.firstBlock?.imgSrc.map((item, index) => {
                                    return <Fragment key={"CAROUSAL_first_desc_" + index + item} >
                                        {/* <BgLayer /> */}
                                        <ImgLayer style={{ width: '100%' }} src={item} />
                                    </Fragment>
                                })
                            }
                        </Carousel>
                    </div>
                </RightBlock>
            </RowBlock>
            <RowBlock>
                <LeftBlock>
                    <BgLayer />
                    <ImgLayer src={project?.detailsData?.secondBlock?.imgSrc} />
                </LeftBlock>
                <RightBlock>
                    <img src={ThreeSeperateLeaf} style={{ position: 'absolute', right: '-234px', top: '-218px', opacity: 0.6 }} />
                    <Title>{project?.detailsData?.secondBlock?.title}</Title>
                    <Desc>{project?.detailsData?.secondBlock?.desc?.title}</Desc>
                    <ul style={{ margin: '10px 0px', padding: '0px', alignSelf: 'flex-start' }} >
                        {project?.detailsData?.secondBlock && project?.detailsData?.secondBlock?.desc?.data.map(o =>
                            <li key={o}>
                                <Desc>{o}</Desc>
                            </li>
                        )}
                    </ul>
                </RightBlock>
            </RowBlock>
            <RowBlock>
                <LeftBlock>
                    <Title>{project?.detailsData?.thirdBlock?.title}</Title>
                    <Desc style={{ fontSize: '24px', lineHeight: '34px' }} >{project?.detailsData?.thirdBlock?.desc?.title}</Desc>
                    <ul style={{ margin: '10px 0px', padding: '0px', alignSelf: 'flex-start' }} >
                        {project?.detailsData?.thirdBlock && project?.detailsData?.thirdBlock?.desc?.data.map(o =>
                            <li key={o}>
                                <Desc style={{ fontSize: '24px', lineHeight: '34px' }} >{o}</Desc>
                            </li>
                        )}
                    </ul>
                </LeftBlock>
                <RightBlock style={{ flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-around' }} >
                    {
                        project?.detailsData?.thirdBlock && project?.detailsData?.thirdBlock?.imgSrc.map((item, index) => {
                            return <div key={"CAROUSAL_third_desc_" + index + item} style={{ marginBottom: '20px', position: 'relative', width: '45%' }} >
                                {/* <BgLayer style={{ width: '240px',height:'240px' }} /> */}
                                <ImgLayer style={{ width: '100%', height: 'auto' }} src={item.imgSrc} />
                                <div style={{ display: 'flex', borderRadius: '38px', backgroundColor: 'rgba(0,0,0,0.3)', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%' }} >
                                    <p style={{ color: '#fff', fontSize: '18px', fontWeight: 600, lineHeight: '21px' }} >{item.title}</p>
                                </div>
                            </div>
                        })}
                </RightBlock>
            </RowBlock>
        </Container>
        <div style={{ display: 'flex', overflow: 'hidden' }} >
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
        </div>
    </Main>
}

export default DetailSection;