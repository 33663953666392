import { styled } from "styled-components";

import leafBg from '../../assets/img/leafLightBg.svg';

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: #FFF;
    padding: 80px 150px;
    background-image: url(${leafBg});
`;

const Title = styled.h4`
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -1.510961651802063px;
    color: #50A300;
    text-align: center;
    margin-bottom: 8px;
`;

const Desc = styled.p`
    font-size: 19px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    width: 70%;
    align-self: center;
`;

const OurPartners = () => {
    return <Container>
        <Title>Our Technical Partners</Title>
        <Desc>Our farms work together with tech partners. Like all our crops go into one basket, these partners help make things smooth. They bring cool farming gadgets and smart ways to move things around. It's like mixing the old farm wisdom with new tech magic for a great harvest!</Desc>
        <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', marginTop: '30px' }} >
            <a href={'https://www.onebasket.in/'} rel="noopener noreferrer" target="_blank" style={{ marginRight: '25px' }} >
                <img src={require('../../assets/img/oneBasketLogo.png')} style={{ width: '150px', height: '150px', border: '1px dashed #50A300' }} />
            </a>
            <a href={'http://www.krishibadlav.com/'} rel="noopener noreferrer" target="_blank" >
                <img src={require('../../assets/img/krishibadlav.png')} style={{ width: '150px', height: '150px', border: '1px dashed #50A300', }} />
            </a>
        </div>
    </Container>
}

export default OurPartners;