import { styled } from "styled-components";
import colors from "../utils/colors";

const Container = styled.div`
    display: flex;
`;

const VideoContainer = styled.div`
    width: 100%;
    position: relative;
    min-height: 50vh;
`;

const BlurLayer = styled.div`
    background-color: rgba(0,0,0,0.6);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BrandText = styled.span`
    font-size: 75px;
`;

const OverlayText = styled.h3`
    text-align: center;
    font-size: 83px;
    color:#FFFFFF;
    max-width: 70%;

    ${BrandText}{
        color:#8AE635;
    }
`;

const HeroVideoSection = ({ title, highlightTitle, }) => {
    return <Container>
        <VideoContainer>
            <video src={require('../assets/video/LandingBgVideo.mp4')} loop muted autoPlay style={{ width: '100%' }} />
            {/* if video not full screen try bottom style */}
            {/* minHeight: '100%', objectFit: 'cover' */}
            <BlurLayer>
                <OverlayText >{title} <BrandText>{highlightTitle}</BrandText></OverlayText>
            </BlurLayer>
        </VideoContainer>
    </Container>
}

export default HeroVideoSection;