import { styled } from "styled-components";
import colors from "../../utils/colors";

const Heading = styled.h2`
    text-align: center;
    font-size: 45px;
    margin-bottom: 15px;
`;

const Body = styled.h5`
    font-size: 20px;
    font-weight: 500;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ colors }) => colors.background};
    padding: 30px 40px;

    ${Heading} {
        color: ${({ colors }) => colors.textDr};
    }

    ${Body} {
        color: ${({ colors }) => colors.textDr};
    }
`;

const Projects = () => {
    return <Container colors={colors} >
        <Heading>On Going Projects By Bhuloka</Heading>
    </Container>
}

export default Projects;