import { useState } from "react";
import { styled } from "styled-components";

import ProjectDp from '../../assets/img/projectDp.svg';
import leafBg from '../../assets/img/leafLightBg.svg';

import LocIcon from '../../assets/img/location.svg';
import { ProjectData } from "../../utils/staticData";
import { Link } from "react-router-dom";

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: #57A227;
    padding: 80px 150px;
    background-image: url(${leafBg});
`;

const HeadingDiv = styled.div`
    background: #FFFFFF;
    border-radius: 0px 21px;
    padding: 0px 40px;
    align-self: center;
    margin-bottom: 30px;
`;

const Heading = styled.h2`
    font-size: 47px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: -2.008742094039917px;
    text-align: center;
    color:#57A227;
`;

const Title = styled.h3`
    font-size: 56px;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: -2.3752310276031494px;
    text-align: center;
    color: #fff;
`;

const Button = styled.a`
    box-shadow: 0px 5px 14px 1px #9BB70D;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 6px 50px;
    border: 0px;
    align-self: center;
    border-radius: 13px;
    margin-top: 20px;
    cursor: pointer;
    text-decoration: none;

    font-size: 37px;
    font-weight: 700;
    line-height: 43px;
    letter-spacing: 0px;
    text-align: center;
    color:#000;

    &:hover{
        color: #569C29;
    }
`;

const KMButton = styled(Link)`
    border-radius: 11px;
    background-color:#66a53e;
    padding: 6px 12px;
    border: 0px;
    cursor: pointer;
    align-self: center;
    margin:20px 0;
    
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    color: #fff;
    text-decoration: none;
    
    &:hover {
        background-color:#569C29;
    }
`;

const ImgBlock = styled.div`
    height: 250px;
    width: 100%;
`;

const ProjectContentBlock = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
`;

const ImgDp = styled.img`
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
`;

const ProjectCard = styled.div`
    display: flex;
    flex-direction: column;
    flex:0 0 31%;
    margin-bottom: 40px;
    margin-right: ${({ index }) => ((index + 1) % 3) ? '20px' : '0px'};
`;

const Icons = styled.img`
    width: 19px;
    height: 19px;
    margin-right: 4px;
`;

const Value = styled.p`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
`;

const CropChips = styled.div`
    border-radius: 4px;
    background: #569C29;
    padding: 2px 8px;
    margin-right: 5px;

    font-size: 8px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #FFFFFF;
`;

const Value1 = styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
`;

const renderProject = (item, index) => {
    return <ProjectCard key={"Render Project:- " + item} index={index} >
        <ImgBlock>
            <ImgDp src={item?.intro?.imgSrc} />
        </ImgBlock>
        <ProjectContentBlock>
            <div style={{ marginBottom: '30px' }} >
                <div style={{ display: 'flex', alignItems: 'flex-start' }} >
                    <Icons src={LocIcon} />
                    <Value>Hyderabad, Telangana</Value>
                </div>
                <Value1>{item.name} Farmland by bhuloka</Value1>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }} >
                <Value1 style={{ flex: 1 }} >Crop(s)</Value1>
                <div style={{ display: 'flex', flex: 1.4, flexWrap: 'wrap' }} >
                    <CropChips>Fruit Bearing</CropChips>
                    <CropChips>Fruit Bearing</CropChips>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }} >
                <Value1 style={{ flex: 1 }} >Investment</Value1>
                <Value style={{ flex: 1.4 }} >Rs. 24 lac* onwards</Value>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }} >
                <Value1 style={{ flex: 1 }} >Land Size</Value1>
                <Value style={{ flex: 1.4 }} >Starting from 6,000 Sq.Ft.</Value>
            </div>
            <KMButton target="_blank" rel="noopener noreferrer" to={'/detail-project/' + item.id} >Know More About Project</KMButton>
        </ProjectContentBlock>
    </ProjectCard>;
}

const ProjectsBy = () => {

    // const [projects,] = useState(['a', 'b', 'c', 'd', 'e', 'f'])

    return <Container>
        <HeadingDiv>
            <Heading>Projects by Bhuloka</Heading>
        </HeadingDiv>
        <Title>We’re not just a farmland company.<br />We’re the future of farming.</Title>
        {/*  */}

        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '60px' }} >
            {ProjectData.map((item, index) => {
                return renderProject(item, index);
            })}
        </div>

        <Button href='/contact-us' >Own Farm Land Now</Button>
    </Container>
}

export default ProjectsBy;