import { styled } from "styled-components";
import colors from "../../utils/colors";

import leafBg from '../../assets/img/leafLightBg.svg';
import ActionButton from "../../components/ActionButton";

const Heading = styled.h4`
    /* text-align: center; */
    font-size: 45px;
    margin-bottom: 15px;
`;

const Heading1 = styled.h4`
    /* text-align: center; */
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 15px;
`;

const Body = styled.h5`
    font-size: 21px;
    font-weight: 300;
`;

const Container = styled.div`
    display: flex;
    position: relative;
    background-color: ${({ colors }) => colors.background};
    padding: 80px 150px;
    background-image: url(${leafBg});

    ${Heading} {
        color: ${({ colors }) => colors.textDr};
    }
    
    ${Heading1} {
        color: ${({ colors }) => colors.textSecondary};
    }

    ${Body} {
        color: ${({ colors }) => colors.textDr};
    }
`;

const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const RightSection = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

const BgLayer = styled.div`
    width: 400px;
    height: 100%;
    border-radius: 150px 0px 150px 0px;
    box-shadow: 0px 5px 28px 4px #9BB70D;
    background: #7FB911;
    position: absolute;
    margin-left: 40px;
    margin-bottom: 40px;
`;

const ImgLayer = styled.img`
    width: 400px;
    height: auto;
    z-index: 9;
`;

const AboutBlock = () => {
    return <Container colors={colors} >
        <LeftSection>
            <Heading>About Us</Heading>
            <Heading1>Why Bhuloka Heavens on Earth</Heading1>
            <Body>To experience heavens on the earth living real in paradise with the fresh breeze, essence of healthy life & wealth of meaningful experiences. The joy of nature and the luxuries of city life, nestled around the Gandorinala Dam view point. Rejoice in abundant natural bounty and tailor-made comforts, our land is a highly exclusive one, located far from any other private and urban settlements, and a short drive to some of Karnataka’s most scenic tourist spots.</Body>
            <ActionButton title='Learn More' href="/about-us" />
        </LeftSection>
        <RightSection>
            <BgLayer />
            <ImgLayer src={require('../../assets/img/aboutImg.png')} />
        </RightSection>
    </Container>
}

export default AboutBlock;