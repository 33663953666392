import { useState } from "react";
import { styled } from "styled-components";

import bgImg from '../../assets/img/ourFarmlandsBgImg.svg';

import GrassStrip from '../../assets/img/grass.svg';

const Main = styled.div`
    display: flex;
    flex-direction: column;
    background-image: url(${bgImg});
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
`;

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
`;

const DataSection = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px 150px 240px 150px;
`;

const Title = styled.h6`
    font-size: 34px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: #50A300;
    margin-bottom: 5px;
`;

const Desc = styled.p`
    font-size: 21px;
    font-weight: 300;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: center;

    width: 60%;
    align-self: center;
`;

const GrassBg = styled.img`
    
`;

const OurFarmlands = () => {

    return <Main>
        <Container>
            <DataSection>
                <Title>Our Farmlands</Title>
                <Desc>Our portfolio boasts an extensive array of green lands, meticulously designed and developed to meet the diverse needs of our clientele. Whether you seek a serene retreat away from the bustling city or a smart investment opportunity, we have the perfect solution.</Desc>
            </DataSection>
        </Container>
        <div style={{ display: 'flex', overflow: 'hidden' }} >
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
        </div>
    </Main>
}

export default OurFarmlands;