import styled from 'styled-components'
import colors from '../utils/colors';
import SocialLinks from './SocialLinks';
import { config } from '../utils/staticData';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #D9D9D9;
    padding: 40px 150px;
`;

const Heading = styled.p`
    font-size: 23px;
    font-weight: 800;
    line-height: 34px;
    letter-spacing: 0px;
    color: #498A26;
    margin-bottom: 8px;
`;

const FirstRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const SecondRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
`;

const AnchorTxt = styled.a`
    cursor: pointer;
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
`;

const Text = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #3E410B;
`;

const Text2 = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
`;

const Col = styled.div`
    display: flex;
    flex-direction: column;
`;

const Footer = () => {

    return <Container colors={colors} >
        <FirstRow>
            <Col>
                <Heading style={{ textAlign: 'center' }} >Company</Heading>
                <ul>
                    <li><AnchorTxt href='/about-us' ><Text>About Us</Text></AnchorTxt></li>
                    <li><AnchorTxt><Text>New offering</Text></AnchorTxt></li>
                    <li><AnchorTxt><Text>Careers</Text></AnchorTxt></li>
                    <li><AnchorTxt href="/contact-us" ><Text>Contact Us</Text></AnchorTxt></li>
                </ul>
            </Col>
            <Col style={{ width: '24%' }} >
                <Heading style={{ textAlign: 'left' }} >Connect</Heading>
                <Text>{config.address}</Text>
                <span style={{ height: '4px' }} />
                <Text>{config.email}</Text>
                <span style={{ height: '4px' }} />
                <Text style={{cursor:'pointer'}} onClick={() => {
                    window.open("tel:" + config.phoneNumber);
                }} >{config.phoneNumber}</Text>
            </Col>
            <Col>
                <Heading style={{ textAlign: 'center' }} >Follow us on</Heading>
                <span style={{ height: '8px' }} />
                <SocialLinks />
                <span style={{ height: '8px' }} />
            </Col>
        </FirstRow>
        <SecondRow>
            <Text2>Copyright © 2023 Bhuloka  On. All Rights Reserved.</Text2>
            <div style={{ display: 'flex', }} >
                <AnchorTxt>
                    <Text2 style={{ marginRight: '30px' }} >Privacy Policy</Text2>
                </AnchorTxt>
                <AnchorTxt>
                    <Text2 style={{ marginLeft: '30px' }} >Terms & Conditions</Text2>
                </AnchorTxt>
            </div>
        </SecondRow>
    </Container>
}

export default Footer;