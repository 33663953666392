import { useState } from "react";
import { styled } from "styled-components";

import leafBg from '../../assets/img/leafLightBg.svg';
import timelineBg from '../../assets/img/Timeliner.svg';

import singleLeaf from '../../assets/img/leafTopRight.svg';
import threeLeaf from '../../assets/img/LeftThreeLeafs.svg';
import singleBottomLeaf from '../../assets/img/topLeftLeaf.svg';
import oneAndHalfLeaf from '../../assets/img/OneandHalfLeaf.svg';


import FistWheatImg from '../../assets/img/fistWheat.svg';
import MoneyPlantImg from '../../assets/img/moneyPlant.svg';
import FarmerFrndImg from '../../assets/img/farmerFriends.svg';

const Container = styled.div`
    display: flex;
    padding: 80px 150px;
    background-image: url(${leafBg});
    flex-direction: column;
    `;

const Title = styled.h6`
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -1.510961651802063px;
    color: #50A300;
    `;

const Body = styled.p`
    font-size: 19px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0px;
    `;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    `;

const TimelineBlock = styled.div`
    display: flex;
    flex-direction: column;
    background-image: url(${timelineBg});
    background-repeat: no-repeat;
    background-position: 50% 100px;
    margin-top: 30px;
    `;

const RowSection = styled.div`
    display: flex;
    position: relative;
    align-items: center;
`;

const ImgSection = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

const BgLayer = styled.div`
    width: 360px;
    height: 100%;
    border-radius: 150px 0px 150px 0px;
    box-shadow: 0px 5px 28px 4px #9BB70D;
    background: #7FB911;
    position: absolute;
    margin-left: 40px;
    margin-bottom: 40px;
`;

const ImgLayer = styled.img`
    width: 360px;
    height: auto;
    z-index: 9;
    border-radius: 50px 0px 150px 0px;
`;

const MainSection = () => {

    return <Container>
        <Section>
            <Title style={{ alignSelf: 'center' }} >About us</Title>
            <Body style={{ textAlign: 'center', margin: '0px 8%' }} >The Ploughmen, as you've described them, are a diverse and experienced team with a rich history rooted in agriculture. Their collective experience spans over a century, and they come from a wide range of industries, including tech, investment banking, real estate development, and agriculture. Despite their diverse backgrounds, there is a common thread that binds them together: a deep-rooted connection to farming and agriculture, as they all hail from agriculturist families. This shared background in agriculture likely provides the Ploughmen with a unique perspective and a deep appreciation for the challenges and opportunities within the agricultural sector</Body>
            <img src={singleLeaf} style={{ position: 'absolute', right: '-160px', bottom: '-80px', opacity: 0.6 }} />
        </Section>
        <TimelineBlock>
            <RowSection style={{ marginBottom: '40px' }} >
                <Section style={{ marginRight: '8%' }} >
                    <Title>How it Started?</Title>
                    <Body>Thanks to COVID and our stay at villages! The realization that, agriculture has moved on from natural organic to heavy Chemicals dependent industry in last 30 years, the feeling of gulping in chemicals on our food plate, morning to evening, its disastrous affect on our future generation, the growth argi tech start ups but without much benefit to farmers, We, the ploughmen, decided to to do a reverse drain. Taking urban souls to rural farms and awaken the farmer in each one of us! because a century back, every one of us, was a farmer!</Body>
                    <img src={threeLeaf} style={{ position: 'absolute', top: '74%', left: '-20%', opacity: 0.6 }} />
                </Section>
                <ImgSection>
                    <BgLayer />
                    <ImgLayer src={FistWheatImg} />
                    <img src={singleBottomLeaf} style={{ position: 'absolute', right: '-160px', top: '50%', opacity: 0.6 }} />
                </ImgSection>
            </RowSection>
            <RowSection>
                <ImgSection>
                    <BgLayer />
                    <ImgLayer src={MoneyPlantImg} />
                </ImgSection>
                <Section style={{ marginLeft: '8%' }} >
                    <Title>What do we do?</Title>
                    <Body>We do not sell fancy lands to you, in the name of farm plots! We have started a sustainable agriculture movement, supported by farmers, Agri tech startups, and give, you, the buyer, a chance to become a ploughman (the farmer) and help you to eat the organic, natural and chemical free fruits vegetables and pulses and re design your dining table with lot of vegan food! We not only sell you farm lands, but help you with development of land, labour arrangement for farming, monthly updates, soil testing, development of agriculture land and maximize your returns by selling your output to a nearby FPO or deliver your farm outputs to your doors! We are a moonlighting farmers company! THE PLOUHGMAN</Body>
                </Section>
            </RowSection>
            <RowSection>
                <Section style={{ marginRight: '10%' }} >
                    <Title>Our Commitment:</Title>
                    <Body>Since our inception, we have dedicated ourselves to the art of crafting green haven’s, turning barren landscapes into thriving agricultural and residential spaces. With each project, we have grown, learned, and evolved, making us a formidable presence in the field.</Body>
                    <img src={oneAndHalfLeaf} style={{ position: 'absolute', right: '-225px', top: '100px', opacity: 0.6 }} />
                </Section>
                <ImgSection>
                    <BgLayer />
                    <ImgLayer src={FarmerFrndImg} />
                </ImgSection>
            </RowSection>
        </TimelineBlock>
    </Container>
}

export default MainSection;