import { styled } from "styled-components";

import BgImg from '../../assets/img/farm_vector.svg';
import ActionButton from "../../components/ActionButton";

const Container = styled.div`
    background-image: url(${BgImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

const Heading = styled.h4`
    font-size: 40px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: -1.794266939163208px;
    text-align: center;
    color: #8E942C;
`;

const Body = styled.p`
    font-size: 21px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: center;
    color: #3E410B;
`;

const DataSection = styled.div`
    display: flex;
    flex-direction: column;
    margin:40px 28% 150px 28%;
`;

const AdHeroSection = () => {
    return <Container>
        <DataSection>
            <Heading>Farmland Is a <br />Smart Investment</Heading>
            <Body>Farmland is a low-risk, high-yield asset. If you want to invest in a stable asset that will deliver strong returns and keep you safe from inflation look no further.</Body>
            <ActionButton alignSelf='center' title="Invest Now" href="/contact-us" />
        </DataSection>
    </Container>
}

export default AdHeroSection;