import { styled } from "styled-components";
import colors from "../../utils/colors";

const Text = styled.h3`
    font-size: 35px;
    margin-right: 30px;
`;

const Button = styled.button`
    padding: 10px;
    font-weight: bold;
    font-size: 20px;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ colors }) => colors.primary};
    padding: 30px 40px;

    ${Text}{
        color: ${({ colors }) => colors.textLt};
    }

    ${Button} {
        color: ${({ colors }) => colors.background};
        background-color: ${({ colors }) => colors.secondary};

        &:hover{
            color: ${({ colors }) => colors.primary};
        }
    }
`;

const DownloadBrochureBanner = () => {
    return <Container colors={colors} >
        <Text>Know more about you Dream Land.</Text>
        <Button>Download Brochure</Button>
    </Container>
};

export default DownloadBrochureBanner;