import { styled } from "styled-components";

import leafBg from '../../assets/img/leafLightBg.svg';

import singleLeaf from '../../assets/img/leafTopRight.svg';
import singleBottomLeaf from '../../assets/img/topLeftLeaf.svg';

const Container = styled.div`
    display: flex;
    position: relative;
    padding: 100px 150px;
    background-image: url(${leafBg});
`;

const Text = styled.p`
    font-size: 29px;
    font-weight: 700;
    line-height: 43px;
    letter-spacing: 0px;
    text-align: center;
    color: #498A26;
`;

const HeroTextual = () => {
    return <Container>
        <img src={singleLeaf} style={{ position: 'absolute', right: '-110px', top: '-10px', opacity: 0.6 }} />
        <img src={singleBottomLeaf} style={{ position: 'absolute', left: '-108px', opacity: 0.6 }} />
        <Text>Agriculture has been the backbone of India's economy for centuries, providing livelihoods to a significant portion of its population. However, traditional farming methods have faced numerous challenges over the years, including unpredictable weather patterns, soil degradation, and insufficient yields.<br />We at ploughmen, are trying to solve this challenge so that future generations don't loose out on the quality food that has always been the priority for Indian household kitchens.</Text>
    </Container>
}

export default HeroTextual;