import { useState } from "react";
import { styled } from "styled-components";

import leafBg from '../../assets/img/leafLightBg.svg';

const Container = styled.div`
    display: flex;
    padding: 150px 240px 150px 150px;
    background-image: url(${leafBg});
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
`;

const BoldText = styled.p`
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    color: #000000;
`;

const RegText = styled.p`
    font-size: 23px;
    font-weight: 400;
    line-height: 34px;
    color: #000000;
`;

const RegText2 = styled.p`
    font-size: 22px;
    font-weight: 300;
    line-height: 26px;
    color: #000000;
`;

const Submit = styled.button`
    background: #509328;
    border: 0;
    padding: 6px 80px;
    cursor: pointer;
    align-self: flex-start;

    font-size: 21px;
    font-weight: 600;
    line-height: 25px;
    color: #fff;
`;

const CInput = styled.input`
    border: 0;
    align-self: stretch;
    border-bottom: 1px solid #000000;
    font-size: 22px;
    font-weight: 300;
    line-height: 26px;
    outline: none;
    padding: 5px;
`;

const PolicyBlock = styled.div`
    display: flex;
    margin-top: 40px;
    margin-bottom: 20px;
`;

const Checkbox = styled.div`
    width: 14px;
    min-width: 14px;
    height: 14px;
    background: ${({ activeBool }) => activeBool ? '#509328' : '#D9D9D9'};
    border:2px solid #D9D9D9;
    margin-right: 10px;
    cursor: pointer;
`;

const CheckText = styled.p`
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    color: #000000;
`;

const MainSection = () => {

    const [acceptPolicy, setAcceptPolicy] = useState(false);

    return <Container>
        <Section>
            <BoldText>Contact Us</BoldText>
            <RegText2>Fill out the form, or call us to set up a free site visit.</RegText2>
            <br />
            <BoldText>Farmland Address</BoldText>
            <RegText2>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</RegText2>
            <br />
            <BoldText>Corporate Address</BoldText>
            <RegText2>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</RegText2>
            <br />
            <RegText>contact@farms.com<br />+91 1234567890</RegText>
        </Section>
        <span style={{ width: '150px' }} />
        <Section>
            <CInput placeholder="Full Name" />
            <br />
            <CInput placeholder="Phone" />
            <br />
            <CInput placeholder="Email" />
            <br />
            <PolicyBlock>
                <Checkbox activeBool={acceptPolicy} onClick={() => {
                    setAcceptPolicy(!acceptPolicy);
                }} />
                <CheckText>I authorize Bhuloka and its representatives to Call,
                    SMS, Email or Whatsapp me about its products and offers.
                    This consent overrides any registration for DND and NDNC</CheckText>
            </PolicyBlock>
            <Submit>Submit</Submit>
        </Section>
    </Container>
}

export default MainSection;