import AcerLoc from '../assets/img/acersLoc.svg';
import LeafIcon from '../assets/img/leafIcon.svg';
import RoadIcon from '../assets/img/RoadIcon.svg';
import FeatureIcon from '../assets/img/FeatureIcon.svg';

const config = {
    phoneNumber: '+918341915573',
    email: 'hello@bhuloka.com',
    address: '4-Floor, Bhukya Lakshmi complex, Camelot layout, Botanical garden road, Kondapur, Hyderabad - 500084',
}

const NavMenu = [
    {
        id: 'NavMenu_1',
        title: 'Home',
        navPath: '/',
    },
    {
        id: 'NavMenu_2',
        title: 'About Us',
        navPath: '/about-us',
    },
    {
        id: 'NavMenu_3',
        title: 'Projects',
        navPath: '/projects',
    },
    {
        id: 'NavMenu_4',
        title: 'Agricultural Methodology',
        navPath: '/agricultural-methodology',
    },
    {
        id: 'NavMenu_5',
        title: 'Contact Us',
        navPath: '/contact-us',
    },
]

const ProjectData = [
    {
        id: 'arka',
        name: 'Arka',
        title: 'Where you ',
        highlightTitle: 'soul meets the soil',
        heroBgImg: require('../assets/img/arkaHeroBanner.png'),
        intro: {
            title: 'With Every Step In To The Nature',
            desc: "\"Bhulokka Arka,\" a term rooted in antiquity symbolizing a ray or burst of lightning, represents a sprawling 1200-acre (Phase 1) tract of farmland set against the backdrop of the scenic Gandorinala Dam. This place is not just a mere agricultural venture; it is a paradise for those who hold an unwavering passion for the art of farming.This vast expanse of land is truly a treasure trove for ploughmen and agriculturists alike. It unfolds as a breathtaking canvas of possibilities, beckoning individuals who are dedicated to the cultivation of the earth. The abundant water resources coursing through this landscape ensure a consistent and reliable supply, making it a fertile ground for agricultural pursuits.One of the standout features of Bhulokka Arka is its exceptional capability to support two crop cycles each year. This dual-harvest potential is a testament to the land's fertility and the favorable climate conditions it enjoys.",
            imgSrc: require('../assets/img/arkaDp.png'),
        },
        parameters: {
            title: "Bhulokka Arka: Where Lightning Strikes",
            desc: 'It is situated near the Gandorinala Dam, making it a haven for passionate ploughmen. With abundant water sources and the ability to cultivate two crops a year, this farm land offers endless possibilities for agriculture.',
            data: [
                {
                    id: 'Param_data_1',
                    imgSrc: AcerLoc,
                    title: '1200 Acres',
                    titleTag: 'in Phase 1',
                    desc: 'Guarantee your security by joining the largest farmland community situated on the riverbank.',
                },
                {
                    id: 'Param_data_2',
                    imgSrc: LeafIcon,
                    title: '80%',
                    titleTag: 'of the project',
                    desc: 'falls within the Green Zone, characterized by an abundance of trees and reduced pollution levels.',
                },
                {
                    id: 'Param_data_3',
                    imgSrc: RoadIcon,
                    title: '1 Hr',
                    titleTag: 'Drive From City',
                    desc: 'Your weekend home is just a 1-hour drive from the city and the Mumbai highway.',
                },
                {
                    id: 'Param_data_4',
                    imgSrc: FeatureIcon,
                    title: '20 +',
                    titleTag: 'of the project',
                    desc: 'We proudly offer over 20 unparalleled luxury amenities that set us apart from any other farm project. We\'re confident in our unique offerings.',
                },
            ]
        },
        rangeSubTitle: 'Farm lots range from 5 acres to 50 acres.',
        detailsData: {
            firstBlock: {
                imgSrc: [
                    require('../assets/img/arka_desc_1_img_1.png'),
                    require('../assets/img/arka_desc_1_img_2.png'),
                    require('../assets/img/arka_desc_1_img_3.png'),
                    require('../assets/img/arka_desc_1_img_4.png'),
                ],
                title: 'Amenities Include',
                desc: {
                    title: '',
                    data: [
                        "Comprehensive teak wood fencing surrounding the property.",
                        "Bore well for efficient water supply.",
                        "On-site farm management handled by dedicated labor.",
                        "Utilization of drones for crop management and monitoring.",
                    ]
                }
            },
            secondBlock: {
                imgSrc: require('../assets/img/arka_desc_2_img.png'),
                title: 'The Finest And Nearest Location.',
                desc: {
                    title: '"We recognize the value of your time, as time and space have become today\'s most precious commodities. Bhuloka Arka enjoys a prime location just a short distance from key areas in Hyderabad."',
                    data: [
                        "175 kilometers from Hyderabad",
                        "30 klm away from Mumbai highway",
                        "15 km from Gulbarga",
                        "Very Near Chandrampalli Dam",
                        "Tourist places within 3km",
                        "Direct land access from the state highway is available'",
                    ]
                }
            },
            thirdBlock: {
                imgSrc: [
                    {
                        title: 'Fruits Farming',
                        imgSrc: require('../assets/img/fruitFarming.png'),
                    },
                    {
                        title: 'Rice Farming',
                        imgSrc: require('../assets/img/riceFarming.png'),
                    },
                    {
                        title: 'Wheat Farming',
                        imgSrc: require('../assets/img/wheatfarming.png'),
                    },
                    {
                        title: 'Vegetable Farming',
                        imgSrc: require('../assets/img/vegetableFarming.png'),
                    },
                ],
                title: 'Benefits Of Invest In Arka',
                desc: {
                    title: '"The land boasts an abundant and reliable water source, making it an ideal location for agriculture. With fertile and cultivable soil, this land allows for the cultivation of two crops in a year, offering a diverse range of agricultural opportunities. Some of the crops that thrive in this fertile environment include rice, wheat, pulses, sugarcane, and various seasonal fruits and vegetables.',
                    data: []
                }
            }
        }
    },
    {
        id: 'medhini',
        name: 'Medhini',
        title: 'Beneath the open sky, ',
        highlightTitle: 'where birds freely fly.',
        heroBgImg: require('../assets/img/medhiniHeroBanner.png'),
        intro: {
            title: 'Welcome To The Place And Evolve With Nature',
            desc: 'Bhuloka Medhini - "Medhini," the ancient name for Earth, comprises 100 meticulously designed acres of farm plots. These plots are tailor-made for passionate travelers seeking a close connection with Mother Nature while embracing contemporary, organic, and natural farming practices, all with the goal of restoring farming to its original Medini essence. Nestled strategically, these 100 acres are situated in proximity to a state highway and are ensconced by picturesque hills. Moreover, they enjoy access to abundant water sources, making this location an ideal haven for those who wish to immerse themselves in the bountiful embrace of the natural world while contributing to the resurgence of agriculture in its purest Medini form.',
            imgSrc: require('../assets/img/medhiniDp.png'),
        },
        parameters: {
            title: "Bhulokka Medhini: Surrounded By Hills",
            desc: 'It is situated near the Gandorinala Dam, making it a haven for passionate ploughmen. With abundant water sources and the ability to cultivate two crops a year, this farm land offers endless possibilities for agriculture.',
            data: [
                {
                    id: 'Param_data_1',
                    imgSrc: AcerLoc,
                    title: '100 Acres',
                    titleTag: 'in Phase 1',
                    desc: 'Guarantee your security by joining the largest farmland community situated on the riverbank.',
                },
                {
                    id: 'Param_data_2',
                    imgSrc: LeafIcon,
                    title: '80%',
                    titleTag: 'of the project',
                    desc: 'falls within the Green Zone, characterized by an abundance of trees and reduced pollution levels.',
                },
                {
                    id: 'Param_data_3',
                    imgSrc: RoadIcon,
                    title: '1 Hr',
                    titleTag: 'Drive From City',
                    desc: 'Your weekend home is just a 1-hour drive from the city and the Mumbai highway.',
                },
                {
                    id: 'Param_data_4',
                    imgSrc: FeatureIcon,
                    title: '20 +',
                    titleTag: 'of the project',
                    desc: 'We proudly offer over 20 unparalleled luxury amenities that set us apart from any other farm project. We\'re confident in our unique offerings.',
                },
            ]
        },
        rangeSubTitle: 'Farm lots range from 1 Acre to 10 Acres.',
        detailsData: {
            firstBlock: {
                imgSrc: [
                    require('../assets/img/medhini_desc_1_img_1.png'),
                    require('../assets/img/arka_desc_1_img_2.png'),
                    require('../assets/img/arka_desc_1_img_3.png'),
                    require('../assets/img/arka_desc_1_img_4.png'),
                ],
                title: 'Amenities Include',
                desc: {
                    title: '',
                    data: [
                        "Comprehensive teak wood fencing surrounding the property.",
                        "Bore well for efficient water supply.",
                        "On-site farm management handled by dedicated labor.",
                        "Utilization of drones for crop management and monitoring.",
                    ]
                }
            },
            secondBlock: {
                imgSrc: require('../assets/img/medhini_desc_2_img.png'),
                title: 'The Finest And Nearest Location.',
                desc: {
                    title: '"We recognize the value of your time, as time and space have become today\'s most precious commodities. Bhuloka Arka enjoys a prime location just a short distance from key areas in Hyderabad."',
                    data: [
                        "175 kilometers from Hyderabad",
                        "30 klm away from Mumbai highway",
                        "15 km from Gulbarga",
                        "Very Near Chandrampalli Dam",
                        "Tourist places within 3km",
                        "Direct land access from the state highway is available'",
                    ]
                }
            },
            thirdBlock: {
                imgSrc: [
                    {
                        title: 'Fruits Farming',
                        imgSrc: require('../assets/img/fruitFarming.png'),
                    },
                    {
                        title: 'Rice Farming',
                        imgSrc: require('../assets/img/riceFarming.png'),
                    },
                    {
                        title: 'Wheat Farming',
                        imgSrc: require('../assets/img/wheatfarming.png'),
                    },
                    {
                        title: 'Vegetable Farming',
                        imgSrc: require('../assets/img/vegetableFarming.png'),
                    },
                ],
                title: 'Benefits Of Invest In Medhini',
                desc: {
                    title: '"The land boasts an abundant and reliable water source, making it an ideal location for agriculture. With fertile and cultivable soil, this land allows for the cultivation of two crops in a year, offering a diverse range of agricultural opportunities. Some of the crops that thrive in this fertile environment include rice, wheat, pulses, sugarcane, and various seasonal fruits and vegetables.',
                    data: []
                }
            }
        }
    }
]

export { NavMenu, ProjectData, config };