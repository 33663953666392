import { styled } from "styled-components";

import leafBg from '../../assets/img/leafLightBg.svg';

import GrassStrip from '../../assets/img/grass.svg';

import img1 from '../../assets/img/img1_dB.svg';
import img2 from '../../assets/img/img2_dB.svg';
import img3 from '../../assets/img/img3_dB.svg';

import singleLeaf from '../../assets/img/leafTopRight.svg';
import oneAndHalfLeaf from '../../assets/img/OneandHalfLeaf.svg';

const Main = styled.div`
    display: flex;
    flex-direction: column;
    background-image: url(${leafBg});
    position: relative;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 80px 150px;
`;

const Text = styled.p`
    font-size: 21px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: justified;
`;

const ImgSection = styled.div`
    display: flex;
    margin-top: 150px;
    margin-bottom: 50px;
    justify-content: center;
`;

const ImgBlock = styled.img`
    border-radius: 35px;
    margin-right: 20px;
    width: 30%;
`;

const GrassBg = styled.img`
    
`;

const DetailBlock = () => {
    return <Main>
        <Container>
            <img src={singleLeaf} style={{ position: 'absolute', left: '-108px', top: '20px', opacity: 0.6 }} />
            <img src={oneAndHalfLeaf} style={{ position: 'absolute', right: '-108px', top: '0px', opacity: 0.6 }} />
            <Text style={{ marginTop: '30px' }} >Precision farming is a game-changer for Indian agriculture. It involves the use of GPS-guided tractors and drones, to precisely manage resources like water, fertilizers, and pesticides. This method helps farmers optimize resource utilization, reduce waste, and improve crop yields. By analysing real-time data, farmers can make informed decisions about when and where to plant, irrigate, and harvest their crops. This technology-driven approach not only boosts productivity but also minimizes environmental impacts.</Text>
            <ImgSection>
                <ImgBlock src={img1} />
                <ImgBlock src={img2} />
                <ImgBlock src={img3} />
            </ImgSection>
            <Text>Modern farming practices encourage crop rotation and diversification, which help improve soil health and reduce the risk of pests and diseases. Instead of monocropping, where a single crop is grown repeatedly, farmers are now encouraged to rotate different crops. This approach enhances soil fertility and reduces the need for chemical inputs. Diversifying crops also provides farmers with multiple sources of income, reducing their dependence on a single crop and mitigating market price fluctuations.</Text>
            <img src={oneAndHalfLeaf} style={{ position: 'absolute', left: '-90px', bottom: '-14%', opacity: 0.6 }} />
            <img src={singleLeaf} style={{ position: 'absolute', right: '-108px', bottom: '-42px', opacity: 0.6 }} />
        </Container>
        <div style={{ display: 'flex', overflow: 'hidden' }} >
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
        </div>
    </Main>
}

export default DetailBlock;