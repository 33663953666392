import { styled } from "styled-components";

import leafBg from '../../assets/img/leafLightBg.svg';
import { ProjectData } from "../../utils/staticData";
import { Link } from "react-router-dom";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 100px 60px;
    background-image: url(${leafBg});
`;

const Title = styled.h5`
    font-size: 77px;
    font-weight: 600;
    line-height: 90px;
    letter-spacing: -1.392965316772461px;
    text-align: center;
    flex: 1;
    margin-bottom: 30px;

    color:#498A26;
`;

const ProjectsBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: center;
`;

const ProjectCard = styled.div`
    flex: 0 0 27%;
    border-radius: 0px 40px 0px 56px;
    background-color: #569C29;
    display: flex;
    padding: 20px;
    margin: 15px;
    flex-direction: column;
`;

const ProjectImgSection = styled.div`
    background-color: #BEBEBE;
    width: auto;
    height: 300px;
    overflow: hidden;
    border-radius: 0px 31px 0px 0px;
`;

const Heading = styled.h4`
    font-size: 23px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: -0.4133891463279724px;
    color: #fff;
    margin-top: 6px;
`;

const LocText = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.2543933391571045px;
    text-align: left;
    color: #fff;
    margin-top: 6px;
`;

const AmountTxt = styled.h6`
    font-size: 23px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: -0.4133891463279724px;
    text-align: left;
    color: #fff;
    margin-top: 6px;
`;

const Button = styled(Link)`
    box-shadow: 0px 4.1338911056518555px 4.1338911056518555px 0px #00000040;
    background-color: #FFFFFF;
    border-radius: 7.23px;
    padding: 6px;
    align-self: flex-end;
    border: 0;

    font-size: 19px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.3501152992248535px;
    text-align: left;
    color:#569C29;
    cursor: pointer;
    text-decoration: none;
`;

const MainSection = () => {

    const renderProjectCard = (item, lastItemBool) => {
        return <ProjectCard lastItemBool={lastItemBool} >
            <ProjectImgSection>
                <img src={item?.intro?.imgSrc} style={{objectFit:'cover',objectPosition:'center bottom'}} />
            </ProjectImgSection>
            <Heading>{item.name}</Heading>
            <LocText>San Francisco, Stoutest Tower CA 94116</LocText>
            {/* <AmountTxt>$ 26,00,000</AmountTxt> */}
            <Button target="_blank" rel="noopener noreferrer" to={'/detail-project/' + item.id} >Book Now</Button>
        </ProjectCard>;
    }

    return <Container>
        <Title>Projects</Title>
        <ProjectsBlock>
            {
                ProjectData.map((item, index) => renderProjectCard(item, index + 1 / 3 === 0))
            }
        </ProjectsBlock>
    </Container>
}

export default MainSection;