import Footer from "../../components/Footer"
import Header from "../../components/Header"
import HeroImageSection from "../../components/HeroImageSection";

import BgImg from '../../assets/img/ProjectsHeroBanner.svg';
import MainSection from "./MainSection";
import LandscapeBlock from "./LandscapeBlock";
import GetInTouch from "../../components/GetInTouch";

const Projects = () => {
    return <>
        <Header />
        <HeroImageSection title={"Welcome to Bhuloka's "} highlightTitle={'Farmland Projects'} imgSrc={BgImg} />
        <MainSection/>
        <LandscapeBlock />
        <GetInTouch bgColor='#498A26' />
        <Footer />
    </>
}

export default Projects;