import Footer from "../../components/Footer"
import Header from "../../components/Header"

import HeroImageSection from "../../components/HeroImageSection";
import MainSection from "./MainSection";
import OurFarmlands from "./OurFarmlands";

import BgImg from '../../assets/img/aboutHeroBanner.svg';
import OurCustomers from "./OurCustomers";
import OurPartners from "./OurPartners";

const AboutUs = () => {
    return <>
        <Header />
        <HeroImageSection title={"Your Gateway to Dream Land - Where "} highlightTitle={'Green Meets Growth'} imgSrc={BgImg} />
        <MainSection />
        <OurFarmlands />
        <OurCustomers />
        <OurPartners />
        <Footer />
    </>
}

export default AboutUs;