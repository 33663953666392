import { styled } from "styled-components";

import leafBg from '../../assets/img/leafLightBg.svg';

import singleLeaf from '../../assets/img/leafTopRight.svg';
import singleBottomLeaf from '../../assets/img/topLeftLeaf.svg';
import oneAndHalfLeaf from '../../assets/img/OneandHalfLeaf.svg';

const Container = styled.div`
    display: flex;
    position: relative;
    padding: 100px 150px;
    background-image: url(${leafBg});
`;

const LeftBlock = styled.div`
    display: flex;
    flex:1;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
`;

const BgLayer = styled.div`
    width: 75%;
    height: 88%;
    border-radius: 37px;
    border: 2px dashed #498A26;
    position: absolute;
    margin-right: 20px;
    margin-bottom: 20px;
`;

const ImgLayer = styled.img`
    width: 75%;
    height: auto;
    z-index: 9;
`;

const RightBlock = styled.div`
    display: flex;
    flex:1;
    margin-left: 20px;
    flex-direction: column;
`;

const Title = styled.h3`
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.392965316772461px;
    text-align: left;
    color: #498A26;
`;

const Desc = styled.p`
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: justified;
`;

const IntroSection = ({ project }) => {
    return <Container>
        <img src={singleLeaf} style={{ position: 'absolute', left: '-110px', top: '20px', opacity: 0.6 }} />
        <img src={singleBottomLeaf} style={{ position: 'absolute', right: '34px', top: '-20%', opacity: 0.6 }} />
        <LeftBlock>
            <BgLayer />
            <ImgLayer src={project?.intro?.imgSrc} />
        </LeftBlock>
        <RightBlock>
            <Title style={{ width: '80%', marginBottom: '10px' }} >{project?.intro?.title}</Title>
            <Desc>{project?.intro?.desc}</Desc>
        </RightBlock>
        <img src={oneAndHalfLeaf} style={{ position: 'absolute', bottom: '-30%', right: '-150px', opacity: 0.6 }} />
    </Container>
}

export default IntroSection;