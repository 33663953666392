
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import AboutBlock from "./AboutBlock";
import DownloadBrochureBanner from "./DownloadBrochureBanner";
import HeroVideoSection from "../../components/HeroVideoSection";
import Projects from "./Projects";
import WhyInvest from "./WhyInvest";
import AdHeroSection from "./AdHeroSection";
import GetInTouch from "../../components/GetInTouch";
import ProjectsBy from "./ProjectsBy";

const Home = () => {
    return <>
        <Header />
        <HeroVideoSection title={"Welcome to your "} highlightTitle={"Oasis of Tranquility & Luxury"} />
        <AboutBlock />
        <AdHeroSection />
        <WhyInvest />
        <ProjectsBy />
        <GetInTouch />
        <Footer />
    </>
}

export default Home;