import { styled } from "styled-components";
import colors from "../../utils/colors";

import leafBg from '../../assets/img/leafLightBg.svg';

import GrassStrip from '../../assets/img/grass.svg';

import Icon1 from '../../assets/img/lotus.svg';
import Icon2 from '../../assets/img/lic.svg';
import Icon3 from '../../assets/img/Uncomplicated.svg';


const StaticData = [
    {
        id: 'StaticData_1_Motive',
        title: 'Peace of Mind',
        body: "We're a team of agricultural experts on a mission to help you earn a high passive income without the need to worry about the day-to-day operations that come with running a farm",
        img: Icon1,
    },
    {
        id: 'StaticData_2_Motive',
        title: 'Wake Up Rested',
        body: "With round-the-clock monitoring, you can wake up each day feeling safe and sound while knowing your assets are in good hands.",
        img: Icon2,
    },
    {
        id: 'StaticData_3_Motive',
        title: 'Peace of Mind',
        body: "Bhuloka take care of all the paperwork, farm management, and payments so you can reap the benefits of owning farmland. We don't believe in complicated contracts and leases.",
        img: Icon3,
    },
]

const Heading = styled.h4`
font-size: 55px;
font-weight: 700;
line-height: 64px;
letter-spacing: -2.3435323238372803px;
text-align: center;
color:#4D5108;
margin-bottom: 70px;
`;

const Body = styled.h5`
    font-size: 21px;
    font-weight: 300;
`;

const Main = styled.div`
    display: flex;
    flex-direction: column;
    background-image: url(${leafBg});
    position: relative;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 80px 150px;

    ${Heading} {
        color: ${({ colors }) => colors.textDr};
    }
    
    ${Body} {
        color: ${({ colors }) => colors.textDr};
    }
`;

const Motives = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0px 50px;
`;

const MotiveCard = styled.div`
box-shadow: 0px 5px 14px 1px #9BB70D;
box-shadow: 0px 4px 4px 0px #00000040;
background: #7FB911;
padding: 30px;
flex: 1;
display: flex;
flex-direction: column;
margin: ${({ index }) => index === 1 ? '0px 20px' : 0};
border-top-left-radius: ${({ index }) => index === 0 ? '50px' : 0};
border-bottom-right-radius: ${({ index }) => index === 2 ? '50px' : 0};
`;

const MCHeading = styled.h4`
font-size: 25px;
font-weight: 700;
line-height: 29px;
letter-spacing: -1.794266939163208px;
text-align: center;
color:#fff;
margin-bottom: 10px;
`;

const MCBody = styled.p`
font-size: 18px;
font-weight: 400;
line-height: 26px;
letter-spacing: 0px;
text-align: center;
color:#fff;
`;

const MCBadge = styled.div`
    background: linear-gradient(0deg, #7FB911, #7FB911),linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border: 5px solid #FFFFFF;
    box-shadow: 0px 4px 6px 0px #00000040;
    width: 140px;
    height: 140px;
    border-radius: 140px;
    align-self: center;
    margin-bottom: 40px;
    margin-top: -80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MCImg = styled.img`

`;

const GrassBg = styled.img`
    
`;

const renderMotiveCard = (item, index) => {
    return <MotiveCard key={item.id} index={index} >
        <MCBadge>
            <MCImg src={item.img} />
        </MCBadge>
        <MCHeading>{item.title}</MCHeading>
        <MCBody>{item.body}</MCBody>
    </MotiveCard>
}

const WhyInvest = () => {
    return <Main>
        <Container colors={colors} >
            <Heading>Why Invest with Bhuloka</Heading>
            <Motives>
                {StaticData.map((item, index) => {
                    return renderMotiveCard(item, index)
                })}
            </Motives>
        </Container>
        <div style={{ display: 'flex',overflow:'hidden' }} >
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
        </div>
    </Main>
}

export default WhyInvest;