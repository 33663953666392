import { styled } from "styled-components";
import colors from "../utils/colors";

import leafBg from '../assets/img/leafLightBg.svg';

import LocIcon from '../assets/img/location.svg';
import PhoneIcon from '../assets/img/phone.svg';
import MailIcon from '../assets/img/mail.svg';
import SocialLinks from "./SocialLinks";
import ActionButton from "./ActionButton";
import { config } from "../utils/staticData";

const Heading = styled.h4`
    /* text-align: center; */
    font-size: 45px;
    margin-bottom: 15px;
`;

const Heading1 = styled.h4`
    /* text-align: center; */
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 15px;
`;

const Body = styled.h5`
    font-size: 21px;
    font-weight: 300;
`;

const Container = styled.div`
    display: flex;
    position: relative;
    background-color: ${({ colors, bgColor }) => bgColor || colors.background};
    padding: 80px 150px;
    background-image: url(${leafBg});

    ${Heading} {
        color: ${({ colors }) => colors.textDr};
    }
    
    ${Heading1} {
        color: ${({ colors }) => colors.textSecondary};
    }

    ${Body} {
        color: ${({ colors }) => colors.textDr};
    }
`;

const FormSection = styled.div`
    display: flex;
    position: relative;
    box-shadow: 0px 4px 21px 10px #00000040;
    background: #57A227;
    width: 100%;
    padding:80px 0px;
    margin-left: 100px;
`;

const ContactUsBlock = styled.div`
    background-image: url(${leafBg});
    box-shadow: 0px 4px 23px 12px #00000040;
    background-color: #fff;
    width:30%;
    min-width:30%;
    max-width:30%;
    padding:60px 20px;
    margin-left: -100px;
`;

const Title = styled.p`
    font-size: 28px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0px;
    color:#1A0E0E;
    margin-bottom: 20px;
`;

const Value = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color:#3E410B;
`;

const Icons = styled.img`
    width: 19px;
    height: 19px;
    margin-right: 4px;
`;

const Title1 = styled.h3`
font-size: 28px;
font-weight: 700;
line-height: 33px;
letter-spacing: 0px;
color: #fff;
`;

const SubTitle = styled.h3`
font-size: 21px;
font-weight: 700;
line-height: 25px;
letter-spacing: 0px;
color: #fff;
margin-bottom: 40px;
`;

const Input = styled.input`
    border-radius: 6px;
    padding: 15px;
    border: 0;
    margin-bottom: 20px;
    width: 80%;
`;

const GetInTouch = ({ bgColor }) => {
    return <Container colors={colors} bgColor={bgColor} >
        <FormSection>
            <ContactUsBlock>
                <Title>Contact Us</Title>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px', width: '80%' }} >
                    <Icons src={LocIcon} />
                    <Value>{config.address}</Value>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' }} >
                    <Icons src={MailIcon} />
                    <Value>{config.email}</Value>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '100px' }} >
                    <Icons src={PhoneIcon} />
                    <Value style={{cursor:'pointer'}} onClick={() => {
                        window.open("tel:" + config.phoneNumber);
                    }} >{config.phoneNumber}</Value>
                </div>
                <SocialLinks />
            </ContactUsBlock>

            <div style={{ display: 'flex', flexDirection: 'column', margin: '20px 60px', flex: 1 }} >
                <Title1>Get In Touch</Title1>
                <SubTitle>Reach Us For More Details</SubTitle>
                <Input placeholder="full name" />
                <Input placeholder="Phone Number" />
                <Input placeholder="Your email" />
                <ActionButton textBgColor="#C6FFB2" textColor="#000" title='Send' />
            </div>

        </FormSection>
    </Container>
}

export default GetInTouch;