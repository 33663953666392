import { styled } from 'styled-components';
import { Link } from 'react-router-dom';

import RightArrow from '../assets/img/rightArrow.svg';

const LearnMoreButton = styled(Link)`
    display: flex;
    align-self: ${({ alignSelf }) => alignSelf || 'flex-start'};
    align-items: center;
    text-align: center;
    background-color:#fff;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #00000040;
    /* box-shadow: -1px 1px 19px 0px #0000007A; */
    border: 0px;
    padding: 0px;
    margin-top: 30px;
    cursor: pointer;
    text-decoration: none;
`;

const LMText = styled.p`
    background-color: ${({ textBgColor }) => textBgColor || '#50A300'};
    padding: 6px;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0px;
    border-radius: 4px;
    color: ${({ textColor }) => textColor || '#fff'};
`;

const ActionButton = ({ title, textBgColor, textColor, alignSelf,href }) => {
    return <LearnMoreButton to={href} alignSelf={alignSelf} >
        <LMText textBgColor={textBgColor} textColor={textColor} >{title}</LMText>
        <img src={RightArrow} style={{ height: '18px', width: '18px', margin: '0 10px' }} />
    </LearnMoreButton>
}

export default ActionButton;