import { useState } from "react";
import { styled } from "styled-components";

import bgImg from '../../assets/img/ProjectLandScapeBg.svg';

import GrassStrip from '../../assets/img/grass.svg';
import ActionButton from "../../components/ActionButton";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    background-image: url(${bgImg});
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
`;

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
`;

const DataSection = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px 150px 240px 150px;
`;

const Title = styled.h6`
    font-size: 34px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: #50A300;
    margin-bottom: 5px;
`;

const Desc = styled.p`
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;

    margin-top: 12%;
    width: 82%;
    align-self: center;
`;

const GrassBg = styled.img`
    
`;

const LandscapeBlock = () => {

    return <Main>
        <Container>
            <DataSection>
                {/* <Title></Title> */}
                <Desc>At Bhuloka, we are committed to transforming your agricultural dreams into reality. Our farmland projects are designed to provide you with the perfect canvas to cultivate your vision. to now more About  our ongoing and upcoming farmlands fill the form now!</Desc>
                <ActionButton title={'Contact Us'} alignSelf='center' href="/contact-us" />
            </DataSection>
        </Container>
        <div style={{ display: 'flex', overflow: 'hidden' }} >
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
            <GrassBg src={GrassStrip} />
        </div>
    </Main>
}

export default LandscapeBlock;