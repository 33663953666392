import Footer from "../../components/Footer"
import Header from "../../components/Header"
import HeroImageSection from "../../components/HeroImageSection";

import BgImg from '../../assets/img/contactHeroBanner.svg';
import MainSection from "./MainSection";

const ContactUs = () => {
    return <>
        <Header />
        <HeroImageSection title={"Contact "} highlightTitle={'Us'} imgSrc={BgImg} />
        <MainSection/>
        <Footer />
    </>
}

export default ContactUs;