import { styled } from "styled-components";

import leafBg from '../../assets/img/leafLightBg.svg';

import ActionButton from '../../components/ActionButton';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 100px 150px;
    background-image: url(${leafBg});
    background-color: #498A26;
`;

const Title = styled.h3`
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.392965316772461px;
    text-align: center;
    color: #fff;
    margin-bottom:20px;
`;

const Desc = styled.h6`
    font-size: 21px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: center;
    color: #fff;
    width: 85%;
    align-self: center;
`;

const ParameterSection = styled.div`
    display: flex;
    flex:1;
    margin-top: 30px;
`;

const ParameterCard = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 15px 4px #00000040;
    background: #FFFFFF;
    border-radius: 14px;
    align-items: center;
    flex:1;
    padding: 20px;
    margin-right: ${({ lastBool }) => lastBool ? '0px' : '20px'};
`;

const ImgBlock = styled.div`
    box-shadow: 0px 4px 4px 0px #00000040;
    border: 2px solid #498A26;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 130px;
    height: 130px;
    width: 130px;
    margin-bottom: 20px;
`;

const PTitle = styled.h4`
    font-size: 21px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: center;
    color: #569C29;
`;

const PTitleTag = styled.h6`
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #569C29;
    margin:2px 0px;
`;

const PDesc = styled.p`
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #5B6C50;
`;

const ShadowBlock = styled.div`
    display: flex;
    min-width: 70%;
    width:70%;
    max-width: 70%;
    flex-direction: column;
    align-self: center;
    position: absolute;
    box-shadow: 4px 4px 20px 6px #00000040;
    background: #FFFFFF;
    border-radius: 35px;
    padding: 30px;
    top: 88%;
    z-index: 99;
`;

const STitle = styled.h5`
    font-size: 41px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -1.392965316772461px;
    text-align: center;
    color: #498A26;
`;

const SSubTitle = styled.p`
    font-size: 41px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -1.392965316772461px;
    text-align: center;
`;

const ParameterBlock = ({ project }) => {

    const renderCard = (item, lastBool) => {
        return <ParameterCard key={item.id} lastBool={lastBool} >
            <ImgBlock>
                <img src={item.imgSrc} />
            </ImgBlock>
            <PTitle>{item.title}</PTitle>
            <PTitleTag>{item.titleTag}</PTitleTag>
            <PDesc style={{ width: '80%' }} >{item.desc}</PDesc>
        </ParameterCard>
    }

    return <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }} >
        <Container>
            <Title>{project?.parameters?.title}</Title>
            <Desc>{project?.parameters?.desc}</Desc>
            <ParameterSection>
                {project?.parameters && project?.parameters?.data.map((item, index) => renderCard(item, project?.parameters?.data.length - 1 === index))}
            </ParameterSection>
        </Container>
        <ShadowBlock>
            <STitle>Expand Your Border With {project.name}</STitle>
            <SSubTitle>{project?.rangeSubTitle}</SSubTitle>
            {/* <ActionButton title={'Contact Us'} alignSelf='center' href="/contact-us" /> */}
        </ShadowBlock>
    </div>
}

export default ParameterBlock;