import { useState } from "react";
import { useParams } from "react-router-dom";

import Footer from "../../components/Footer"
import Header from "../../components/Header"
import HeroImageSection from "../../components/HeroImageSection";
import GetInTouch from "../../components/GetInTouch";
import { ProjectData } from "../../utils/staticData";
import IntroSection from "./IntroSection";
import ParameterBlock from "./ParameterBlock";
import DetailSection from "./DetailSection";

const DetailProject = () => {

    const { projectSlugId } = useParams();
    console.log(projectSlugId, " =- =- ", ProjectData.find((item) => item.id === projectSlugId));
    let tempObj = ProjectData.find((item) => item.id === projectSlugId);
    const [project,] = useState(tempObj === undefined ? {} : tempObj)

    return <>
        <Header />
        <HeroImageSection textWidth={'52%'} title={project.title} highlightTitle={project.highlightTitle} imgSrc={project.heroBgImg} />
        <IntroSection project={project} />
        <ParameterBlock project={project} />
        <DetailSection project={project} />
        <GetInTouch bgColor='#498A26' />
        <Footer />
    </>
}

export default DetailProject;